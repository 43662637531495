import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import Order from '../../../models/Order';
import EntityManager from '../../../services/EntityManager';
import TextIcon from '../../common/TextIcon';
import SmartInputText from '../../input/input/SmartInputText';
import SmartSelect from '../../input/input/SmartSelect';
import OrderCompositionItem from './OrderCompositionItem';
import Timer from './Timer';
import DOMService from '../../../services/DOMService';
import CommentModal from '../../modal/CommentModal';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

export interface IOrderCardProps {
  order: Order
  onStatusChange: (order: Order) => void
  onCardClick?: () => void
  printerStatus: boolean
}

export interface IOrderCardState {
}

export default class OrderCard extends React.Component<IOrderCardProps, IOrderCardState> {

  componentRef = React.createRef<HTMLDivElement>();

  isPaid = () => this.props.order.status.isSameOf("order_status_paid");
  isPreparing = () => this.props.order.status.isSameOf("order_status_preparing");
  isDelivering = () => this.props.order.status.isSameOf("order_status_delivering");
  isDelivered = () => this.props.order.status.isSameOf("order_status_delivered");
  isCanceled = () => this.props.order.status.isSameOf("order_status_canceled");
  isReady = () => this.props.order.status.isSameOf("order_status_ready");

  async updateStatus(path: string, params = {}) {
    let updated = (await EntityManager.post<Order>(Order, { path: this.props.order.id + "/" + path, params })).model
    this.props.onStatusChange(updated);
  }

  getScheduledOptions() {
    let start = dayjs();
    const remainder = 15 - (start.minute() % 15);
    let first = dayjs().add(remainder, "minute");
    let options: Dayjs[] = [first];
    for (let index = 0; index < 8; index++) {
      options.push(options[options.length - 1].add(15, "minute"))
    }
    return options.map((o, i) => ({ label: o.format("HH:mm") + "-" + o.add(15, "minute").format("HH:mm"), value: o.toDate() }));
  }

  handlePrint(order) {
    const epos = new window.epson.ePOSDevice();
    epos.connect("192.168.1.250", 8008, function (resultConnect) {
      if (resultConnect === "OK") {
        epos.createDevice("local_printer", epos.DEVICE_TYPE_PRINTER, { "crypto": false, "buffer": false }, function (deviceObj, errorCode) {
          if (deviceObj) { 
            //const order = this.props.order;
            const printer = deviceObj;
            
            printer.addTextSize(2, 2);
            printer.addText("Commande " + order.number + "\n");
            printer.addText(order.deliveryMode.label + "\n");
            printer.addText(order.user.getFullName() + "\n");
            if(order.user.phone){
              printer.addText(order.user.phone + "\n");
            }
            printer.addText(`${order.address}${order.addressComp ? ', ' + order.addressComp : ''}, ${order.cp} ${order.city}\n\n`)

            order.orderCompositions.map(((compo) => {
              if (compo.product.baseProduct.isPizza()){
                printer.addText(compo.product.baseProduct.name + " " +compo.product.size.label)
                printer.addText("\n");
              }else{
                printer.addText(compo.product.baseProduct.name)
                printer.addText("\n");
               } 
              if (compo.extras.length > 0) {
                printer.addText(compo.product.baseProduct.isPmP() ? "Composition" : "Extras")
                printer.addText("\n");
                if(compo.product.baseProduct.isPmP()){
                  printer.addText(compo.product.baseProduct.productCategory.label);
                  printer.addText("\n");
                }
                
                
                compo.extras.map((extra) => {
                  printer.addText(extra.product.baseProduct.name + " X" + extra.quantity + "\n");
                })
              }
              printer.addText("\n");
              printer.addText("\n");
            }))
            printer.addText("\n");
            printer.addText(order.total> 0 ? order.total.toFixed(2) + "€": "0€")
            printer.addText("\n");
            printer.addText("\n");
            printer.addText("\n");
            printer.addCut(printer.CUT_FEED);
            printer.send();
          }
        });
      }
    });
  }

  menuButton() {
    return <Dropdown>
      <Dropdown.Toggle as={React.forwardRef(({ children, onClick }: any, ref: React.LegacyRef<HTMLButtonElement>) => (
        <button ref={ref} className="btn text-dark btn-link" style={{ marginRight: "-1rem" }} onClick={onClick}>
          <i className="fas fa-ellipsis-v"></i>
        </button>
      ))} />
      <Dropdown.Menu align="right" className="fade" style={{ marginTop: "1rem" }} >
        {(!this.isDelivered() && !this.isCanceled()) && <Dropdown.Item onClick={() => this.updateStatus("cancel")} >
          <TextIcon leftIcon="far fa-cancel">Annuler la commande</TextIcon>
        </Dropdown.Item>}
        <Dropdown.Item onClick={()  => this.handlePrint(this.props.order)} >
                <TextIcon leftIcon="far fa-print">Imprimer</TextIcon>
              </Dropdown.Item>
        <Dropdown.Item onClick={() => DOMService.modal(<CommentModal order={this.props.order} onSend={() => { this.setState({}) }} />)} >
          <TextIcon leftIcon="far fa-comment">{!this.props.order.adminComment ? "Ajouter un commentaire" : "Modifier le commentaire"}</TextIcon>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  }

  renderFooter(order: Order) {
    if (this.isPaid()) {
      let options = this.getScheduledOptions();
      order.scheduledAt = options[0].value;
      return (
        <div className="row-between">
          <div className="w-75">
            <SmartSelect model={order} placeholder="Estimer l'heure de livraison" name="scheduledAt" options={this.getScheduledOptions()} />
          </div>
          <button onClick={() => this.updateStatus("preparing", { scheduledAt: order.scheduledAt })} className="btn btn-primary w-25">Valider</button>
        </div>
      )
    }
    else if (this.isDelivered()) return (
      <div className="p-2 text-success">
        <TextIcon leftIcon={"fas fa-check"}>Livraison effectuée le {dayjs(order.deliveredAt).format("D MMMM YYYY à HH:mm")}</TextIcon>
      </div>
    )
    else if (this.isDelivering() || this.isPreparing() || this.isReady()) return (
      <div className="row-flex border-top order-footer">
        <div className="text-center w-50">
          <Timer referenceDate={order.scheduledAt} />
        </div>
        {(this.isPreparing() && order.deliveryMode.key === "take_away") && <div onClick={() => this.updateStatus("ready")} className="btn btn-primary w-50">Prêt</div>}
        {(this.isPreparing() && order.deliveryMode.key !== "take_away") && <div onClick={() => this.updateStatus("delivering", { deliveryMan: order.deliveryMan })} className="btn btn-primary w-50">En livraison</div>}
        {(this.isDelivering() || this.isReady()) && <div onClick={() => this.updateStatus("delivered")} className="btn btn-primary w-50">Livrée</div>}
      </div>
    )
    else return <div></div>
  }

  public render() {
    const { order, onCardClick } = this.props;
    return (
      <div className="order-card" ref={this.componentRef} >
        <div onClick={() => onCardClick()}>
          <div className="order-header" style={{ backgroundColor: order.status.color + "1a" }}>
            <div className="font-weight-bold status-order" style={{ color: order.status.color }}>{order.status.label}</div>
            <div className="d-flex">
              <div className="text-right">
                <div className="font-weight-bold">{order.number}</div>
                <div className="text-secondary">{dayjs(order.createdAt).format("D MMMM YYYY HH:mm")}</div>
              </div>
              {this.menuButton()}
            </div>
          </div>
          {(this.isPreparing() && order.deliveryMode.key !== "take_away") && <SmartInputText model={order} name="deliveryMan" placeholder="Livreur" />}
          {this.renderFooter(order)}
          <div className="order-scrollable-body">
            <div className="order-body">
              <div>{order.deliveryMode.label}</div>
            </div>
            <div className="order-body">
              {order.orderCompositions.map(compo => <OrderCompositionItem key={compo.getElementId()} compo={compo} />)}
            </div>
            <div className="order-body">
              <div>Sous total: {order.subtotal.toFixed(2)}€</div>
              <div>Livraison: {order.shippingCost.toFixed(2)}€</div>
              <div>Pourboire: {order.pit ? (order.pit / 100).toFixed(2) : 0}€</div>
              <div>Réduction: {order.discount ? (order.discount) : 0}€</div>
              <div>Total: {order.total> 0 ? order.total.toFixed(2): 0}€</div>
            </div>
            {order.userGift &&
              <div className="order-body">
                <div>{order.userGift.gift.title}</div>
              </div>
            }
            <div className="order-body row-between">
              <div className="capitalize">{order.user.getFullName()}</div>
              <div>{order.user.phone}</div>
            </div>
            <div className="order-address mb-1">
              <div>{order.address}</div>
              {order.addressComp && <div>{order.addressComp}</div>}
              <div>{[order.cp, order.city].join(" ")}</div>
            </div>
            {order.comment && <div className="m-2 top-border-dashed">
              <div>Commentaire du client</div>
              <div>
                {order.comment}
              </div>
            </div>}
            {order.adminComment && <div className="m-2 top-border-dashed">
              <div>Commentaire d'Eurythmeal</div>
              <div>
                {order.adminComment}
              </div>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}
